import $ from 'jquery'

export default {
  init() {
    // JavaScript to be fired on all pages
    // usercentrics settings modal
    $('a').on('click', function () {
      if ($(this).attr('href').includes('#uc-corner-modal-show')) {
        window.UC_UI.showSecondLayer()
      }
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    // on scroll
    $(window).on('scroll', function () {
      dhsv_headerscroll()
    })
    // on document ready
    dhsv_paint_bg()
    dhsv_gform_label()
    dhsv_gform_footer()
    dhsv_smooth_scrolling_anchor()
    dhsv_mobile_menu()
  },
}

function dhsv_headerscroll() {
  var scrollTop = $(window).scrollTop()

  $('header.banner').each(function () {
    if (scrollTop > 100) {
      $(this).addClass('scroll')
    } else {
      $(this).removeClass('scroll')
    }
  })
}

function dhsv_paint_bg() {
  $('.paint-green').each(function () {
    var elheight = $(this).height()
    //console.log('paint-green: ' + elheight)
    if (elheight > 60) {
      $(this).addClass('multiline')
    }
  })
}

function dhsv_gform_label() {
  if ($('.gform_wrapper').length) {
    $('.gform_wrapper .top_label input').on('focus', function () {
      $(this).parent().parent().addClass('current-focus')
    })
    $('.gform_wrapper .top_label input').on('focusout', function () {
      $(this).parent().parent().removeClass('current-focus')
    })
  }
}

function dhsv_gform_footer() {
  if ($('.gform_wrapper').length) {
    var gform_footer_html = $('.gform_wrapper form .gform_footer')[0].outerHTML
    $('.gform_wrapper form .gform_body').append(gform_footer_html)
  }
}

function dhsv_smooth_scrolling_anchor() {
  //var hashTagActive = ''
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .on('click touchstart', function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') ==
          this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash)
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault()
          $('html, body').animate(
            {
              scrollTop: target.offset().top,
            },
            1000,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target)
              $target.focus()
              if ($target.is(':focus')) {
                // Checking if the target was focused
                return false
              } else {
                $target.attr('tabindex', '-1') // Adding tabindex for elements not focusable
                $target.focus() // Set focus again
              }
            }
          )
        }
      }
    })
}

function dhsv_mobile_menu() {
  $('#mobile-menu').on('click tap', function () {
    $('#mobile-menu-container').addClass('open-mobile-menu')
  })

  $('#mobile-menu-close').on('click tap', function () {
    $('#mobile-menu-container').removeClass('open-mobile-menu')
  })
}
